<template>
  <div class="">
    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="() => {}"
    >
      <div class="add-form-inner">
        <div class="form-group-wrapper-item title">
          <h1>{{ $t("EQUIPMENTS.EQUIPMENT_INFORMATIONS") }}</h1>
        </div>

        <div class="form-group-wrapper full">
          <div class="form-group-wrapper-item">
            <image-selector
              :label="$t('COMMON.PICTURE')"
              :defaultImage="equipment.icon"
              ressource_name="equipments"
              :ressource_id="equipment.id ? equipment.id : 0"
              field="icon"
              @imageChanged="
                (file_url) => {
                  equipment.icon = file_url;
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.icon" />
          </div>
        </div>

        <!-- Equipment name -->
        <div class="form-group-wrapper-item full">
          <base-input
            :label="`${$t('EQUIPMENTS.EQUIPMENT_NAME')} (*)`"
            v-model="equipment.name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.name" />
        </div>

        <!-- Category -->
        <div class="form-group-wrapper-item full">
          <base-input
            :label="`${$t(`EQUIPMENTS.EQUIPMENT_CATEGORY_NAME`)} (*)`"
          >
            <equipment-category-selector
              :equipmentCategory="equipment.category?.id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="equipment.organization?.id"
              @equipmentCategoryChanged="
                (equipmentCategory) => {
                  equipment.category.id = equipmentCategory;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.category" />
        </div>

        <!-- Excerpt -->
        <div class="form-group-wrapper-item full">
          <base-input
            :label="$t('COMMON.COMMENT')"
            :placeholder="$t('COMMON.COMMENT')"
          >
            <html-editor v-model="equipment.excerpt" @change="onFormChanged()">
            </html-editor>
          </base-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </div>
      </div>

      <div class="submit-wrapper">
        <base-button
          class="btn cancel"
          type="button"
          @click="onCloseEquipmentModal"
        >
          {{ $t("COMMON.CANCEL") }}
        </base-button>
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          :disabled="loading || processing"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
          {{ equipment.id ? $t("COMMON.EDIT") : $t("COMMON.ADD_ITEM") }}
        </base-button>
      </div>
    </form>

    <modal
      :show="showEquipmentCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("EQUIPMENTS.SAME_EQUIPMENTS", {
                name: equipment.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="equipments"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="100"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('EQUIPMENTS.EQUIPMENT_CATEGORY_NAME')"
                prop="category.name"
                min-width="100"
              />
              <el-table-column min-width="50">
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip
                    :content="$t('COMMON.VIEW')"
                    placement="top"
                    v-if="$currentUserCan($permissions.PERM_VIEW_EQUIPMENTS)"
                  >
                    <a
                      type="text"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <router-link :to="$objectViewRoute(row)">
                        <i class="fal fa-expand-alt"></i>
                      </router-link>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click.prevent="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleEquipmentCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select, Table, TableColumn } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { defaultAddressInformation } from "@/constants/defaultAddressInformation";
import "flatpickr/dist/flatpickr.css";
import EquipmentCategorySelector from "@/components/EquipmentCategorySelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    EquipmentCategorySelector,
    ImageSelector,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  props: ["equipmentData", "formErrors", "loading"],

  data() {
    let equipmentData = { ...this.equipmentData };
    return {
      equipment: equipmentData,
      equipments: [],
      total: 0,
      showModal: false,
      defaultAddressInformation,
      showEquipmentCheckModal: false,
      processing: false,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      const checkUniqueEquipment = await this.checkEquipmentUniqueInformation();
      if (checkUniqueEquipment) {
        await this.sendInformation();
      } else {
        this.showEquipmentCheckModal = true;
      }
    },

    async sendInformation() {
      let equipmentData = cloneDeep(this.equipment);
      this.$emit("equipmentSubmitted", equipmentData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.equipment?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.equipment?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "category",
        };

        await this.$store.dispatch("equipments/list", params);
        this.equipments = this.$store.getters["equipments/list"];
        this.total = this.$store.getters["equipments/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkEquipmentUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.equipment?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleEquipmentCheckModalState();
      await this.sendInformation();
    },

    async toggleEquipmentCheckModalState() {
      this.showEquipmentCheckModal = !this.showEquipmentCheckModal;
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },

    onCloseEquipmentModal() {
      this.$emit("onCloseEquipmentModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    equipmentData(equipmentData) {
      if (equipmentData) {
        this.equipment = {
          ...this.equipment,
          ...cloneDeep(equipmentData),
        };
      }
    },
  },
};
</script>
